import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import NewsSchema from '../components/NewsSchema';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import richTextOptions from '../utils/richTextOptions';
import { BLOCKS } from '@contentful/rich-text-types';

const NewsPost = ({ data }) => {
  const {
    title,
    publishDate,
    slug,
    body,
    seotitle,
    seodescription,
    author,
    updatedAt,
  } = data.contentfulNews;
  const references = body.references || [];

  const richTextOptionsWithReferences = {
    renderNode: {
      ...richTextOptions.renderNode,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return richTextOptions.renderNode[BLOCKS.EMBEDDED_ASSET](
          node,
          children,
          { references }
        );
      },
    },
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: '当事務所からのお知らせ', url: 'https://www.involve-in.jp/news' },
    { name: title, url: `https://www.involve-in.jp/news/${slug}` },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{seotitle || title}</title>
        <meta name="description" content={seodescription} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.involve-in.jp/news/${slug}`} />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={seotitle || title} />
        <meta property="og:description" content={seodescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.involve-in.jp/news/${slug}`}
        />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seotitle || title} />
        <meta name="twitter:description" content={seodescription} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <NewsSchema article={data.contentfulNews} />

      <article className="max-w-screen-lg w-full mx-auto my-16 p-12 bg-white bg-opacity-95 shadow-lg rounded-lg text-black">
        <h1 className="text-5xl mb-10 font-bold text-center">{title}</h1>
        <div className="bg-black bg-opacity-80 text-white text-lg px-6 py-3 rounded-lg shadow-md inline-block mb-6">
          公開日: {publishDate}
          {updatedAt && updatedAt !== publishDate && (
            <> | 更新日: {updatedAt}</>
          )}
        </div>

        <div className="text-2xl leading-relaxed space-y-8">
          {documentToReactComponents(
            JSON.parse(body.raw),
            richTextOptionsWithReferences
          )}
        </div>

        {author && (
          <div className="mt-20 p-12 rounded-2xl shadow-xl max-w-screen-lg w-full mx-auto border border-gray-400">
            {/* タイトル */}
            <h3 className="text-4xl font-bold text-gray-900 dark:text-white mb-6 pb-3 border-b-4 border-gray-400">
              この記事を書いた人
            </h3>

            <div className="flex flex-col md:flex-row items-center md:items-start space-y-8 md:space-y-0 md:space-x-12">
              {/* 著者アイコン（角丸スクエアに変更） */}
              {author.avatar && (
                <img
                  src={author.avatar.url}
                  alt={author.name}
                  className="w-44 h-44 rounded-2xl border-4 border-gray-500 shadow-xl object-cover"
                />
              )}

              {/* 著者情報 */}
              <div className="text-center md:text-left">
                <h4 className="text-5xl font-bold text-gray-900 dark:text-white">
                  {author.name}
                </h4>
                {author.bio && (
                  <p className="mt-4 text-2xl text-gray-800 dark:text-gray-300 leading-relaxed">
                    {author.bio}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </article>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export const query = graphql`
  query NewsPostBySlug($id: String!) {
    contentfulNews(id: { eq: $id }) {
      title
      slug
      publishDate(formatString: "YYYY年MM月DD日")
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            file {
              url
            }
            title
          }
        }
      }
      seotitle
      seodescription
      author {
        name
        avatar {
          url
        }
        bio
      }
      updatedAt(formatString: "YYYY-MM-DD")
    }
  }
`;

export default NewsPost;
