import React from 'react';
import { Helmet } from 'react-helmet';

const NewsSchema = ({ article }) => {
  if (!article) {
    return null;
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: article.title,
    datePublished: article.publishDate,
    dateModified: article.updatedAt, // ✅ `updatedAt` を適用！
    author: {
      '@type': 'Person',
      name: article.author?.name || '',
      image: article.author?.avatar?.url || '',
    },
    publisher: {
      '@type': 'Organization',
      name: 'INVOLVE',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.involve-in.jp/images/logo.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.involve-in.jp/news/${article.slug}`,
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default NewsSchema;
